<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <app-nav-bar></app-nav-bar>
  <app-hero-section></app-hero-section>
  <app-supported-lang></app-supported-lang>
  <app-footer-section></app-footer-section>
</template>

<script>
import { defineComponent } from 'vue'
import Nav from './NavBarLanding.vue'
import Hero from './HeroSection.vue'
import Footer from './FooterLanding.vue'
import Languages from './SupportedLang.vue'
export default defineComponent({
  components: {
    'app-nav-bar': Nav,
    'app-hero-section': Hero,
    'app-footer-section': Footer,
    'app-supported-lang': Languages,
  },
});
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50; */
  /* margin-top: 60px; */
}
</style> 