<template>
<div class="main-content" id="lang">
    <v-row class="image-row-container" no-gutters>
        <v-col cols="10" md="2" sm="4">
            <router-link to="c">
                <v-img src="assets/images/C.png" alt="C" class="image"></v-img>
            </router-link>
        </v-col>
        <v-col cols="10" md="2" sm="4">
            <router-link to="cpp">
                <v-img src="assets/images/Cpp.png" alt="C" class="image"></v-img>
            </router-link>
        </v-col>
        <v-col cols="10" md="2" sm="4">
            <router-link to="java">
                <v-img src="assets/images/Java.png" alt="C" class="image"></v-img>
            </router-link>
        </v-col>
        <v-col cols="10" md="2" sm="6">
            <router-link to="python"><v-img src="assets/images/Python.png" alt="C" class="image"></v-img></router-link>
        </v-col>
        <v-col cols="10" md="2" sm="6">
            <router-link to="js">
                <v-img src="assets/images/JavaScript.png" alt="C" class="image"></v-img>
            </router-link>
        </v-col>
    </v-row>
    <div class="tagline-1-container">
        <h1 class="tagline-1">CODE SMARTER NOT HARDER</h1>
    </div>
    <div class="tagline-2-container">
        <h2 class="tagline-2">Effortlessly elevate your coding experience</h2>
    </div>
    <div class="tagline-3-container">
        <p class="tagline-3">Code with ease using our online compiler, which supports C, C++, Java, JavaScript, and Python. Compile, run, and test your code without the need for local installations. With our user-friendly interface, you can code effortlessly and efficiently.</p>
    </div>
</div>
  
</template>

<script>
</script>

<style scoped>
.main-content {
    margin-top: 150px;
    text-align: center;
    /* padding-top: 2px; */
}

.tagline-1 {
    margin-top: 90px;
    margin-bottom: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 800;
    color: blue;
    font-size: 1rem;
    letter-spacing: 1.5px;
}

.tagline-2-container {
    width: 30%;
    margin: auto;
}
.tagline-3-container {
    width: 35%;
    margin: auto;
}

.tagline-2 {
    margin-bottom: 30px;
    font-size: 2rem;
    font-weight: 700;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.tagline-3 {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: rgb(136, 136, 136);
    font-weight: 500;
    font-size: 1rem;
}

.image-row-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.hover {
  transform: scale(1.2);
}
.image {
    height: 100px;
    width: 100px;
    margin: auto;
  /* border: 3px solid #ccc; */
  border-radius: 50%;
  transition: all 0.3s ease;
}

.image:hover {
    cursor: pointer;
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}
</style>
