<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light mb-2" style="height: 5%">
    <!-- <a class="navbar-brand" href="#">YoCoder</a> -->
    <img  src="assets/images/yoCoder.png" class="logo" />
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <a class="nav-item nav-link active" href="#">Practice</a>
        <a class="nav-item nav-link" href="#">Problems</a>
        <a class="nav-item nav-link" href="#">Discussions</a>
        <!-- <a class="nav-item nav-link disabled" href="#">Disabled</a> -->
      </div>
    </div>
  </nav>
</template>

<script>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo {
  width: 70px;
  height: 45px;
  margin-left: 2px;
  margin-right: 2px;
  /* margin-right: 20px;
  margin-top: 15px; */
}
</style>
