<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <app-nav-bar></app-nav-bar>
  <app-code-editor :language="desiredLanguage"> 
  </app-code-editor>
  <app-footer></app-footer>
</template>

<script>
import { defineComponent, ref } from 'vue'
import CodeEditor from './CodeEditor.vue'
import NavBar from './NavBar.vue'
import FooterCompo from './FooterComponent.vue'
export default defineComponent({
  components: {
    'app-code-editor': CodeEditor,
    'app-nav-bar': NavBar,
    'app-footer': FooterCompo,
  },
  props: {
    language: {
        type: String,
        required: true,
    }
  },
  setup(props) {
    const desiredLanguage = ref(props.language)
    return {
        desiredLanguage,
    }
  }
});
</script>