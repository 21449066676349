import { createApp } from 'vue'
import App from './App.vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import { createRouter, createWebHistory } from 'vue-router'

import OnlineCompiler from './components/OnlineComiler/index.vue'
import HomeCompo from './components/landingPage/index.vue'

const routes = [
  { path: '/', component: HomeCompo },
  { path: '/c', component: OnlineCompiler, props: {language: 'C'} },
  { path: '/cpp', component: OnlineCompiler, props: {language: 'C++'} },
  { path: '/java', component: OnlineCompiler, props: {language: 'Java'} },
  { path: '/python', component: OnlineCompiler, props: {language: 'Python'} },
  { path: '/js', component: OnlineCompiler, props: {language: 'JavaScript'} },
]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp(App)
app.use(vuetify)
app.use(router)
app.mount('#app')
// createApp(App).use(vuetify).mount('#app')

const $toast = useToast();
let instance = $toast.success('You did it!');

// Force dismiss specific toast
instance.dismiss();

// Dismiss all opened toast immediately
$toast.clear();