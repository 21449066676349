<template>
  <div class="row">
    <div class="col-lg-8">

      <div class="row">
        <div class="col-lg-6">
          <v-select class="selectLang"
            :items="languages"
            label="Select Language"
            v-model="selectedLang"
            @update:modelValue="setTheEditorLanguage"
          ></v-select>
        </div>

        <!-- <div class="col-lg-5"> -->
          <!-- <v-select class="selectLang"
            :items="themes"
            label="Select Theme"
            v-model="selectedTheme"
            @update:modelValue="setTheEditorTheme"
          ></v-select> -->
        <!-- </div> -->
        <div class="col-lg-6 text-right">
          <v-btn color="primary" class="btn" @click="sendTheCode">Run</v-btn>
        </div>
      </div>

      <codemirror
        v-model="code"
        placeholder="Select the language.."
        :style="{ height: '575px', width: '100%' }"
        :autofocus="true"
        :indent-with-tab="true"
        :tab-size="4"
        :extensions="extensions"
        @ready="handleReady"
        @change="log('change', $event)"
        @focus="log('focus', $event)"
        @blur="log('blur', $event)"
      />
    </div>
    <div class="col-lg-4" ref="parentWindow">
      <div class="row">
        <label for="">Input</label> <br>
        <textarea v-model="codeInput" ref="fillWindow" class="window" rows="5" cols="17"></textarea>
      </div>
      <div class="row" style="width: '30%'">
        <label id="out-label">
          {{ outputWindow.title }} 
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="blue"
          ></v-progress-circular> 
          <span v-if="showOutput">&#9989; </span>
          <span v-if="showError"> &#10060;</span>
        </label>
        <br>
        <textarea v-model="codeOutput" ref="fillWindow" class="window" id="out-win" rows="16" readonly></textarea>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref, shallowRef, onBeforeMount } from 'vue'

  import { Codemirror } from 'vue-codemirror'
  import { javascript } from '@codemirror/lang-javascript'
  import { cpp } from '@codemirror/lang-cpp'
  import { java } from '@codemirror/lang-java'
  import { python } from '@codemirror/lang-python'
  import { oneDark } from '@codemirror/theme-one-dark'
  
  import axios from 'axios'
  import qs from 'qs'

  import {useToast} from 'vue-toast-notification';
  import 'vue-toast-notification/dist/theme-sugar.css';
  export default defineComponent({
    components: {
      Codemirror
    },
    props: {
      language: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const languages = ref([
        'C',
        'C++',
        'Java',
        'JavaScript',
        'Python',
      ])
      const themes = ref([
        'Light',
        'Dark',
        'Default',
      ])
      const selectedLang = ref(props.language)
      const selectedTheme = ref(null)
      const code = ref('')
      const extensions = []
      const editorLang = ref(null)
      const codeOutput = ref('')
      const codeInput = ref('')
      const template = ref('')

      const loading = ref(false)
      const outputWindow = ref({title: 'Output'})
      const showOutput = ref('false')
      const showError = ref('false')
      
      const templateForC = ref(
`#include<stdio.h>

int main() {
    //Code goes here..

    return 0;
}
`)
      const templateForCpp = ref(
`#include<iostream>

using namespace std;

int main() {
    //Code goes here..

    return 0;
}
`)
      const templateForJava = ref(
`public class CodingAge {
    public static void main(String[] args) {
        // Code goes here..

    }
}
`)
      const templateForJavaScript = ref('console.log("Hello World")')
      const templateForPython = ref(`print()`)
      
      // Codemirror EditorView instance ref
      const view = shallowRef()
      const handleReady = (payload) => {
        view.value = payload.view
      }

      function setTheEditorTheme() {
        switch(selectedTheme.value) {
          case 'Light':
            extensions.splice(extensions.indexOf(oneDark))
            break
          case 'Dark':
            if(extensions.indexOf(oneDark) == -1) {
              extensions.push(oneDark)
            }
            break
          case 'Default':
            if(extensions.indexOf(oneDark) == -1) {
              extensions.push(oneDark)
            }
            break
        }
      }

      function setTheEditorLanguage() {
        extensions.splice(0, extensions.length)
        switch(selectedLang.value) {
          case 'C':
            extensions.push(cpp())
            editorLang.value = 'c'
            code.value = templateForC.value
            break
          case 'C++':
            extensions.push(cpp())
            editorLang.value = 'cpp'
            code.value = templateForCpp.value
            break
          case 'JavaScript':
            extensions.push(javascript())
            editorLang.value = 'js'
            code.value = templateForJavaScript.value
            break
          case 'Java':
            extensions.push(java())
            editorLang.value = 'java'
            code.value = templateForJava.value
            break
          case 'Python':
            extensions.push(python())
            editorLang.value = 'py'
            code.value = templateForPython.value
            break
        }
        setTheEditorTheme()
      }

      function changeWindowColorAndText(color, text) {
        outputWindow.value.title = text
        if(color === "red") {
          document.getElementById('out-win').style.color = color
        } else {
          document.getElementById('out-win').style.color = 'black'
        }
        document.getElementById('out-win').style.borderColor = color
        document.getElementById('out-label').style.color = color
      }

      const $toast = useToast();

      function adjustLoadersAndIcons(loader, output, error) {
        showError.value = error
        showOutput.value = output
        loading.value = loader
      }

      function sendTheCode() {
        adjustLoadersAndIcons(true, false, false)
        outputWindow.value.title = 'Executing..'
        const data = qs.stringify({
          'code': `${code.value}`,
          'language': editorLang.value,
          'input': codeInput.value
        });

        const config = {
          method: 'post',
          url: 'https://api.codex.jaagrav.in',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: data
        }

        axios(config)
          .then(response => {
            if(response.data.status === 200) {
              if(response.data.error !== "") {
                const comingError = response.data.error;
                const uniqueCode = comingError.substring(0, comingError.indexOf('.')+1)
                const error = comingError.replace(new RegExp(uniqueCode, "g"), "")
                console.log("ERROR - " + error)
                // console.log(response.data);
                const count = (comingError.split("error").length - 1);
                changeWindowColorAndText('red', (count === 1 ? `1 Error` : `${count} Errors`))
                codeOutput.value = error
                adjustLoadersAndIcons(false, false, true)
              } else {
                changeWindowColorAndText('green', 'Output')
                codeOutput.value = response.data.output
                adjustLoadersAndIcons(false, true, false)
              }
            } else {
              $toast.open({
                message: 'Oops! Something went wrong..',
                type: 'error',
                position: 'bottom',
                duration: '10000',
              });
              adjustLoadersAndIcons(false, false, false)
            }
          })
          .catch(error => {
            console.log(error);
            if(code.value === '') {
              $toast.open({
                message: 'Code Not Found',
                type: 'info',
                position: 'bottom',
              });
            }
            if(editorLang.value === null) {
              $toast.open({
                message: 'Language Not Selected',
                type: 'info',
                position: 'bottom',
              });
            }
            
            if(code.value == '' || editorLang.value == null) return
            $toast.open({
              message: 'Oops! We are sorry. The wesite is currently down. Please check you internet connection also.',
              type: 'error',
              position: 'bottom',
              duration: '10000',
            });
            adjustLoadersAndIcons(false, false, false)
          });
          
        }
      
      onBeforeMount(() => {
        adjustLoadersAndIcons(false, false, false)
        setTheEditorLanguage(props.language)
      })

      return {
        code,
        extensions,
        handleReady,
        log: console.log,
        languages,
        selectedLang,
        setTheEditorLanguage,
        sendTheCode,
        editorLang,
        codeOutput,
        codeInput,
        changeWindowColorAndText,
        template,
        templateForC,
        themes,
        selectedTheme,
        setTheEditorTheme,
        loading,
        outputWindow,
        showOutput,
        showError,
        adjustLoadersAndIcons,
      }
    }
  })
</script>

<style scoped>
.btn {
  margin: 20px;
}
.selectLang {
  width: 50%;
  margin-left: 20px;
}
.window {
  margin: 20px;
  border: 2px solid black;
  width: 92%;
  padding: 5px;
}
.editor {
  width: 60%;
}
</style>