<template>
  <footer>
    <p>Made with <span class="heart">&hearts;</span> at <a href="https://www.codingage.biz/">Coding Age</a></p>
  </footer>
</template>

<style>
footer {
  background-color: #ffffff;
  color: #000000;
  padding: 20px;
  text-align: center;
  font-size: 14px;
}

.heart {
  color: red;
}
a {
  text-decoration: none;
  color: black;
}
</style>
