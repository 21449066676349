<template>
  <div class="hero">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" class="text-center">
          <img src="assets/images/hero.png" alt="Image" class="hero-image">
        </v-col>
        <v-col cols="12" md="6" class="text-center">
          <!-- <div class="hero-content"> -->
            <h1 class="hero-title">Online Compiler for Coders</h1>
            <p class="hero-description">Code and Compile on the go - Your one-stop destination for C, C++, Java, JavaScript, and Python programming!</p>
            <a href="#lang"><button color="primary" class="supported-languages-btn">Choose Language</button></a>
          <!-- </div> -->
        </v-col>
        <!-- <v-col cols="12" md="4">
          <img src="assets/images/hero.png" alt="Image" class="hero-image">
        </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.hero {
  background-color: transparent;
  margin-top: 80px; /* Adjust as needed for spacing between navbar and hero section */
}

.hero-image {
  width: 80%;
  /* height: auto; */
  height: 400px;
}

.hero-content {
  text-align: center;
}

.hero-title {
  font-size: 2.5rem;
  margin-bottom: 2.5rem;
  font-weight: 800;
}

a {
  text-decoration: none;
  color: beige;
}

.hero-description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: rgb(166, 166, 166);
}

.supported-languages-btn {
  margin-top: 2rem;
  padding: 20px;
  border-radius: 40px;
  height: 70px;
  width: 250px;
  background-color: blueviolet;
  color: white;
  font-weight: 700;
  font-family: sans-serif;
  font-size: 1rem;
  letter-spacing: 1px;
}

.supported-languages-btn:hover {
  background-color: rgb(174, 87, 255);
}
</style>
