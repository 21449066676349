<template>
  <nav class="navbar">
    <div class="navbar-logo">
      <img src="assets/images/yoCoder.png" alt="Logo">
    </div>
    <!-- <div class="navbar-links">
    </div> -->
  </nav>
</template>

<style scoped>
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    height: 100px;
    /* background-color: blue; */
  }

  .navbar-logo {
    /* margin-left: 10px; */
    margin: auto;
    margin-top: 30px;
  }

  .navbar-links {
    margin-right: 10px;
  }

  img {
    height: 90px;
    width: 132px;
  }
</style>
