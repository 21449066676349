<template>
  <footer>
    <v-row class="footer-content" no-gutters>
        <v-col cols="12" sm="6" md="6" ls="6">
            <h1>Code to become Coder</h1>
        </v-col>
        <v-col cols="12" sm="6" md="6" ls="6">
            <p>Made with <span class="heart">&hearts;</span> at <a href="https://www.codingage.biz/">Coding Age</a></p>
        </v-col>
    </v-row>
  </footer>
</template>

<style scoped>
  .heart {
    color: red;
  }

  /* Custom styles */
   footer {
    /* background-color: #ffffff; */
    background-image: url('footer.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 200px;
    text-align: center;
    margin-top: 50px;
  }
  a {
  text-decoration: none;
  color: beige;
}
  .footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: beige;
}

p {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  margin-left: 10px;
  color: beige;
}

</style>
